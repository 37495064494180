// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app\\\\components\\\\Input.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app\\components\\Input.tsx");
  import.meta.hot.lastModified = "1718604620999.1404";
}
// REMIX HMR END

import { forwardRef } from 'react';
import { cn } from '~/utils/style';
export const Input = forwardRef(_c = ({
  className,
  ...rest
}, ref) => <input className={cn('w-full', 'rounded', 'border-2', 'border-zinc-500', 'text-zinc-900', 'dark:text-zinc-50', 'bg-zinc-50', 'dark:bg-zinc-700', 'px-2', 'py-1', className)} {...rest} ref={ref} />);
_c2 = Input;
Input.displayName = 'Input';
var _c, _c2;
$RefreshReg$(_c, "Input$forwardRef");
$RefreshReg$(_c2, "Input");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;